<template>
  <div class="welcome mb-4">
    <div class="text-center">
      <div class="font-weight-bold text-h4">
        Welcome to The Anjaneyam Family
      </div>

      <div class="ma-2">
        Ayurveda is the art and science of holistic healing. It is an immersive
        experience that helps to create and maintain an equilibrium between the
        body, mind and soul. Would you rather pop a few pills and feel better or
        create a space for healing, nourshing and rejuvenating? At Anjaneyam, we
        focus on healing the underlying root cause rather than treating only the
        symptoms of an ailment.
      </div>
      <div class="ma-2">
        Our holistic approach to wellness helps people transform their lives and
        maintain a healthy lifestyle. A team of dedicated doctors, nurses and
        staff will help create personalized healing experiences. This may be a
        combination of medicine, therapy, balanced diet, exercise, meditation
        and lifestyle improvements. Our competent team will help you discover
        health and sustainable wellness in an authentic and traditional
        Ayurvedic way. We strongly believe that Ayurveda is not just a medical
        science; it is a way of life. Ayurveda focuses primarily on " Swasthasya
        swasthya rakshanam - Maintenance of one's health and later about
        "Athurasya vikara prashamanam" - Treating of diseases.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeTo",
};
</script>

<style>
.welcome {
  background-image: url("../assets/designs/ourdoctors-1.png");
  background-position: center;
  background-repeat: repeat-x;
  background-size: cover;
}
</style>
