<template>
  <div class="history text-center pa-4">
    <v-row justify="center" align="center">
      <!-- <v-img
        src="@/assets/designs/vision-left.png"
        contain
        max-width="20"
      ></v-img> -->
      <div class="text-h4 font-weight-bold mx-2">Vision of Sri Anjaneyam</div>
      <!-- <v-img
        src="@/assets/designs/vision-right.png"
        contain
        max-width="20"
      ></v-img> -->
    </v-row>

    <div class="ma-2">
      Sri Anjaneyam Ayurveda Vaidyasala, Kumbakonam is just a small
      reciprocation to my guru Dr. Sanal Chandran, BAMS who brought me into this
      beautiful world of Ayurveda. He always says the solution to todays erratic
      human life is following the traditions of Ayurveda dharma. Rishis of
      Bharat did not just contemplate a set of rules to follow. They gave us
      balance in physical and mental wellbeing. Adopting the local tradition,
      paramparaya dishes, dress codes and practices will simply lead us to
      disease-free living.
    </div>
    <div class="text-right">-Dr. M. Manogar, Cheif Doctor, BAMS</div>
  </div>
</template>

<script>
export default {
  name: "VisionOf",
};
</script>

<style>
.history {
  background-color: rgb(21, 136, 0, 0.2);
}
</style>
