<template>
  <div class="ourdoctor">
    <div>
      <v-img src="@/assets/OurVaidyasala.png"> </v-img>
    </div>
    <div class="text-h4 font-weight-bold ma-8 text-center">
      History of Anjaneyam
    </div>
    <v-row justify="center">
      <v-col cols="12" sm="4" class="text-center" align-self="center">
        <v-card
          max-width="250"
          max-height="300"
          class="mt-4 mx-auto"
          elevation="10"
        >
          <v-img src="@/assets/sanalchandra.jpeg"></v-img>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" class="mt-8 mx-auto">
        <div class="ma-8">
          Sri Anjaneyam Ayurveda Vaidyasala, Kumbakonam is just a small
          reciprocation to my guru Dr. Sanal Chandran, BAMS who brought me into
          this beautiful world of Ayurveda. He always says the solution to
          todays erratic human life is following the traditions of Ayurveda
          dharma. Rishis of Bharat did not just contemplate a set of rules to
          follow. They gave us balance in physical and mental wellbeing.
          Adopting the local tradition, paramparaya dishes, dress codes and
          practices will simply lead us to disease-free living. My Guru at
          Anjaneyam Ayurveda Hosptial, Payyanur, Kerala not just treats patients
          but also gives them the knowledge of Yoga, Kalari, our dharma and its
          Samskaras. He also practices traditional way of agriculture, cattle
          farming, and medicine preparation. Just like our Vaidyasala in
          kumbakonam, there are other branches at Andrapradesh and Madhyapradesh
          where the same tradition is being followed.
        </div>
      </v-col>
    </v-row>

    <div class="text-right ma-8">-Dr. M. Manogar BAMS, Chief Doctor</div>
  </div>
</template>

<script>
export default {
  name: "OurVaidyasala",
};
</script>

<!-- <style>
.ourdoctor {
  background-image: url("../assets/designs/ourdoctors-1.png");
  background-position: center;
  background-repeat: repeat-x;
  background-size: cover;
}
</style> -->
